<template>
  <div>
    <v-layout pb-6 pr-6 pl-6 class="mainfont" wrap justify-center>
      <v-flex xs12 pt-4>
        <v-layout wrap justify-center>
          <v-snackbar v-model="showSnackBar" color="black">
            <v-layout wrap justify-center>
              <v-flex text-left class="align-self-center">
                <span style="color: white">
                  {{ msg }}
                </span>
              </v-flex>
              <v-flex text-right>
                <v-btn small :ripple="false" text @click="showSnackBar = false">
                  <v-icon style="color: white">mdi-close</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-snackbar>
          <vue-element-loading :active="appLoading" :is-full-screen="true" background-color="#FFFFFF" color="#42275a"
            spinner="spinner" />
        </v-layout>
        <v-layout wrap justify-center>
          <v-flex xs12 pt-8 text-left>
            <span style="font-size: 25px; font-weight: bolder">PENDING APPLICATIONS</span>
          </v-flex>
        </v-layout>
        <v-layout row wrap justify-center pt-16>
          <v-flex xs12 sm4 md4 lg2>
            <v-card @click="redirectToPage('/t3page')" class="bgcustom">
              <v-card-title>
                <v-layout wrap justify-center>
                  <v-flex text-center>
                    <span style="color: white;">T3</span>
                  </v-flex>
                </v-layout>
              </v-card-title>
            </v-card>
          </v-flex>&nbsp;&nbsp;&nbsp;
          <v-flex xs12 sm4 md4 lg2>
            <v-card @click="redirectToPage('/T6page')" class="bgcustom">
              <v-card-title>
                <v-layout wrap justify-center>
                  <v-flex text-center>
                    <span style="color: white;">T6</span>
                  </v-flex>
                </v-layout>
              </v-card-title>
            </v-card>
          </v-flex>&nbsp;&nbsp;&nbsp;
          <v-flex xs12 sm4 md4 lg2>
            <v-card @click="redirectToPage('/T10page')" class="bgcustom">
              <v-card-title>
                <v-layout wrap justify-center>
                  <v-flex text-center>
                    <span style="color: white;">T10</span>
                  </v-flex>
                </v-layout>
              </v-card-title>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
// import moment from "moment";

export default {
data() {
  return {
    // cdate: moment().format("YYYY-MM-DD"),
    curIdToDelete: null,
    name: null,
    reason: null,
    editdialog: false,
    barlist: [],
    edit: [],
    userProfileTabs: null,
    subtab: null,
    appLoading: false,
    page: 1,
    keyArray: [],
    pages: 0,
    usernames: [], // Array to store username options
    selectedUsername: null, // To store the selected username
    dialog1: false,
    preview: null,
    tab: null,
    barlist1: [],
    gender: ["Male", "Female", "Transgender"],
    dialog3: false,
    deletedialog: false,
    minAmount: null,
    password: null,
    categoryarray: [],
    list: [],
    flag: false,
    flag2: false,
    auctionbased: [],
    currentpage: 1,
    menu1: false,
    cdate: null,
    menu2: false,
    menu3: false,
    menu4: false,
    fromDate: null,
    toDate: null,
    fromDate2: null,
    toDate2: null,
    msg: null,
    currentPage: 1,
    showSnackBar: false,
    users: [],
    username1: null,
    username2: null,
    keyword:"",
    dialog2: false,
    viewdialog: false,
  };
},
methods: {
  redirectToPage(path) {
    this.$router.push(path);
  },
  formatDate(item) {
    var dt = new Date(item);
    var year = dt.getFullYear();
    dt = dt.toString();
    var strTime = dt.slice(4, 10) + " " + year;
    return strTime;
  },

  getUsers(appid) {
    axios({
      method: "post",
      url: "/clerk/forward/list",
      headers: {
        token: localStorage.getItem("token"),
      },
      data: {
        appid: appid,
      },
    })
      .then((response) => {
        this.usernames = [
          // {
          //   name: `${response.data.applicant.username} (User)`,
          //   role: "User",
          //   userId: response.data.applicant._id,
          // },
          {
            name: `${response.data.superintendent.username} (Superintendent)`,
            role: "Superintendent",
            userId: response.data.superintendent._id,
          },
        ];
      })
      .catch((err) => {
        this.ServerError = true;
        console.log(err);
      });
  },
  forward(appids) {
    if (!this.selectedUsername) {
      this.$refs.snackbar.showSnackbar("Please select a user to forward to.");
      return;
    }
    const selectedUser = this.usernames.find(
      (user) => user.name === this.selectedUsername
    );
    if (!selectedUser) {
      return;
    }

    axios({
      method: "post",
      url: "/forward/",
      headers: {
        token: localStorage.getItem("token"),
      },
      data: {
        appid: appids,
        forwardto: selectedUser.userId,
        reason: this.reason,
      },
    })
      .then((response) => {
        if (response.data.status == true) {
          this.msg = response.data.msg;
          this.showSnackBar = true;
          window.location.reload();
        } else {
          this.msg = response.data.msg;
          this.showSnackBar = true;
        }
      })
      .catch((err) => {
        this.ServerError = true;
        console.error(err);
      });
  },

},
};
</script>
  
<style>
.subheading {
font-family: mainfont;
color: #42275a;
text-transform: none;
font-size: 17px;
font-weight: bold;
}

.heading {
font-family: mainfont;
color: rgb(229, 111, 8);
text-transform: none;
font-size: 25px;
}
</style>